import React, { useState, useEffect } from 'react'
import DebounceButton from './Button'

const BackToTop = () => {
  const [visible, setVisible] = useState(false)

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)
    return () => {
      window.removeEventListener('scroll', toggleVisibility)
    }
  }, [])

  return (
    <div className="fixed bottom-16 right-8 !z-40 lg:bottom-8">
      <DebounceButton
        isIconOnly
        onClick={scrollToTop}
        color="primary"
        className={`rounded-full p-2 text-lg shadow-md ${visible ? 'visible' : 'invisible'} transition-all duration-300`}
      >
        ↑
      </DebounceButton>
    </div>
  )
}

export default BackToTop
