import { join, sampleSize } from 'lodash-es'

import { toast } from 'sonner'

let clickable = true

const debouncedFC = (click) => {
  if (clickable) {
    click()
    clickable = false
    setTimeout(() => {
      clickable = true
    }, 3000)
  }
}

const alphanumList = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
export function randomAlphanums(len) {
  return join(sampleSize(alphanumList, len), '')
}

export const notify = (message, type = 'success', position = 'top-center') => {
  debouncedFC(() =>
    toast(message, {
      type,
      position,
      duration: 1500,
    })
  )
}

export const waitTime = (time = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, time)
  })
}
