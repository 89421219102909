import React from 'react'

import view from '@/models/view'

import { UmiContext } from './helpers/constant'
import Dispatcher from './helpers/dispatcher'
import Executor from './helpers/executor'

export const models = {
  view,
}

const dispatcher = new Dispatcher()
const Exe = Executor

const Provider = ({ children }) => {
  return (
    <UmiContext.Provider value={dispatcher}>
      {Object.entries(models).map(([namespace, hook]) => (
        <Exe
          key={namespace}
          namespace={namespace}
          hook={hook}
          onUpdate={(val) => {
            dispatcher.data[namespace] = val
            dispatcher.update(namespace)
          }}
        />
      ))}
      {children}
    </UmiContext.Provider>
  )
}

export default Provider
