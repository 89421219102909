import React from 'react'
import '@/style/tailwind.css'
import '@/style/global.css'
import 'react-image-crop/dist/ReactCrop.css'
import 'highlight.js/styles/ir-black.min.css' // 你可以选择其他样式，例如 'default', 'dracula' 等
import { SWRConfig } from 'swr'
import { ThemeProvider as NextThemesProvider } from 'next-themes'
import { NextUIProvider } from '@nextui-org/react'
import dynamic from 'next/dynamic'
import ModelProvider from '@/plugin-model/Provider'
import Head from '@/components/Header'

import Layout from '@/components/Layout'

const ToastContainer = dynamic(() => import('@/components/Toast'), { ssr: false })

const MyApp = ({ Component, pageProps }) => {
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <ModelProvider>
      <NextUIProvider className="h-full">
        <NextThemesProvider attribute="class" defaultTheme="dark">
          <SWRConfig value={{ revalidateOnFocus: true, refreshInterval: 900000 }}>
            <Head component={Component} />
            <Layout need_login={pageProps.need_login} keyword={pageProps.keyword}>
              {getLayout(<Component {...pageProps} />)}
            </Layout>
          </SWRConfig>
          <ToastContainer autoClose={3000} limit={1} />
        </NextThemesProvider>
      </NextUIProvider>
    </ModelProvider>
  )
}

export default MyApp
