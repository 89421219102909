import React from 'react'
import { Image } from '@nextui-org/react'
import Login from '@/components/SignIn'

const Component = () => {
  return (
    <div className="rounded-lg">
      <div className="px-8 py-16">
        <div className="mb-4 flex items-center justify-center gap-3 text-3xl">
          <Image src="/logo.png" alt="" width={35} height={35} />
          <div>Ground AI</div>
        </div>
        <div className="mb-12 text-center text-xs text-primary">智绘无限 绘就无限</div>
        <Login />
      </div>
    </div>
  )
}

export default Component
