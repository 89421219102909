import useSWR from 'swr'
import { get, getToken } from '@/utils/request'

const getUserSpaceImages = async (page, pageSize, sortId, hostUserId) => {
  const res = await get(`v1/images/fetch_user_space_images?page=${page}&limit=${pageSize}&sort_id=${sortId}&host_user_id=${hostUserId}`)
  if (res.code === 200) {
    return res.data
  }
  return []
}

const getCreatedImages = async (page, pageSize, sortId) => {
  const res = await get(`v1/user/fetch_created_images?page=${page}&limit=${pageSize}&sort_id=${sortId}`)
  if (res.code === 200) {
    return res.data
  }
  return []
}

const useCreatedImages = (page, pageSize, sortId) => {
  const { data, mutate, isValidating } = useSWR(getToken() && `created_images`, () => getCreatedImages(page, pageSize, sortId))
  return { data: data ?? [], mutate, isValidating }
}

const getLikedImages = async (page, pageSize, sortId) => {
  const res = await get(`v1/user/fetch_liked_images?page=${page}&limit=${pageSize}&sort_id=${sortId}`)
  if (res.code === 200) {
    return res.data
  }
  return []
}

const useLikedImages = (page, pageSize, sortId) => {
  const { data, mutate, isValidating } = useSWR(getToken() && `liked_images`, () => getLikedImages(page, pageSize, sortId))
  return { data: data ?? [], mutate, isValidating }
}

const getUserInfo = async () => {
  const res = await get(`v1/user/fetch_user_basic_info`)
  if (res.code === 200) {
    return res.data
  }
  return {}
}

const useProfile = () => {
  const { data, mutate, isValidating } = useSWR(getToken() && `profile`, getUserInfo)
  if (data) {
    localStorage.setItem('user_id', data.id)
  }
  return { data: data ?? {}, mutate, isValidating }
}

const getUserSpaceInfo = async (id) => {
  const res = await get(`v1/user/fetch_user_info?user_id=${id}`)
  if (res.code === 200) {
    return res.data
  }
  return {}
}

const useUserSpaceProfile = (id) => {
  const { data, mutate, isValidating } = useSWR(`user_info`, () => getUserSpaceInfo(id))
  return { data: data ?? {}, mutate, isValidating }
}

export { getUserSpaceImages, getCreatedImages, useCreatedImages, getLikedImages, useLikedImages, getUserInfo, useProfile, getUserSpaceInfo, useUserSpaceProfile }
