import isEqual from 'fast-deep-equal'
import { useContext, useEffect, useRef, useState } from 'react'

import { UmiContext } from './helpers/constant'

export function useModel(namespace, updater) {
  const dispatcher = useContext(UmiContext)
  const updaterRef = useRef(updater)
  updaterRef.current = updater
  const [state, setState] = useState(() => {
    try {
      return updaterRef.current ? updaterRef.current(dispatcher.data[namespace]) : dispatcher.data[namespace]
    } catch (e) {
      console.warn(e)
      return {}
    }
  })
  const stateRef = useRef(state)
  stateRef.current = state

  const isMount = useRef(false)
  useEffect(() => {
    isMount.current = true
    return () => {
      isMount.current = false
    }
  }, [])

  useEffect(() => {
    const handler = (e) => {
      if (!isMount.current) {
        // 如果 handler 执行过程中，组件被卸载了，则强制更新全局 data
        setTimeout(() => {
          dispatcher.data[namespace] = e
          dispatcher.update(namespace)
        })
      } else if (updater && updaterRef.current) {
        const currentState = updaterRef.current(e)
        const previousState = stateRef.current
        if (!isEqual(currentState, previousState)) {
          setState(currentState)
        }
      } else {
        setState(e)
      }
    }
    try {
      dispatcher.callbacks[namespace].add(handler)
      dispatcher.update(namespace)
    } catch (e) {
      if (dispatcher.callbacks !== undefined) {
        dispatcher.callbacks[namespace] = new Set()
        dispatcher.callbacks[namespace].add(handler)
        dispatcher.update(namespace)
      }
    }
    return () => {
      try {
        dispatcher.callbacks[namespace].delete(handler)
      } catch (e) {
        console.warn(e)
      }
    }
  }, [namespace]) // eslint-disable-line react-hooks/exhaustive-deps

  return state
}
