import produce from 'immer'
import { useCallback, useState } from 'react'
import localStorage from 'localStorage'
import { set, get } from 'lodash-es'

const defaultState = {
  loginDialogOpen: false,
  hasMore: true,
  page: 1,
  images: undefined,
  user_id: localStorage.getItem('user_id') ?? '',
  imageParams: null,
  pageSize: 20,
  imageSamples: 0,
  imageLoading: false,
  imageResults: [],
  searchPage: 1,
  searchHasMore: true,
  searchImages: undefined,
  inviteDialogOpen: false,
  rewardDialogOpen: false,
}

const ViewModel = () => {
  const [view, viewReplace] = useState(defaultState)
  const viewSet = useCallback((path, value) => viewReplace(produce((draft) => set(draft, path, typeof value === 'function' ? value(get(draft, path)) : value))), [])
  return { view, viewReplace, viewSet }
}

export default ViewModel
