import React, { useEffect, useState } from 'react'
import { Input, Checkbox } from '@nextui-org/react'
import Link from 'next/link'
import { useForm } from 'react-hook-form'
import { Turnstile } from '@marsidev/react-turnstile'
import { clearToken, setToken, post } from '@/utils/request'
import Button from '@/components/Button'
import { getLoginCode } from '../services/auth'
import { notify } from '@/utils/crypto'
import { useModel } from '@/plugin-model/useModel'
import { useProfile } from '@/swr/useProfile'

const STIE_KEY = process.env.NEXT_PUBLIC_SITE_KEY
const Login = () => {
  const { viewSet } = useModel('view')
  const { mutate } = useProfile()
  const [checked, setChecked] = useState(true)
  const [codeSent, setCodeSent] = useState(false)
  const [clToken, setClToken] = useState('')
  const [countDown, setCountDown] = useState(60)
  const [btnText, setBtnText] = useState('发送验证码')
  const [loading, setLoading] = useState(false)

  const postLogin = async (params) => {
    const res = await post('v1/user/login', { ...params })
    return res
  }

  const login = async (params) => {
    setLoading(true)
    clearToken()
    const res = await postLogin(params)
    if (res.access_token) {
      setToken(res.access_token)
      viewSet('loginDialogOpen', false)
      viewSet('user_id', res.user.id)
      localStorage.setItem('user_id', res.user.id)
      mutate()
      setLoading(false)
    } else {
      notify(res.message, 'warning')
      setLoading(false)
    }
  }
  const { register, watch, handleSubmit, getValues } = useForm({
    mode: 'onBlur',
  })

  useEffect(() => {
    if (codeSent && countDown > 0) {
      setTimeout(() => setCountDown(countDown - 1), 1000)
      setBtnText(`${countDown} s`)
    } else if (countDown <= 0) {
      setBtnText('重新发送')
      setCodeSent(false)
      setCountDown(60)
    }
  }, [countDown, codeSent])

  return (
    <form
      onSubmit={handleSubmit((values) => {
        login({ account: values.account, code: values.code })
      })}
    >
      <Input
        isRequired
        variant="bordered"
        autoFocus
        placeholder="手机号码"
        className="mb-6 w-full"
        name="account"
        {...register('account')}
        startContent={
          <div className="pointer-events-none flex items-center">
            <span className="text-small text-default-400">+86</span>
          </div>
        }
      />
      <div className="flex items-center justify-between gap-4">
        <Input variant="bordered" placeholder="验证码" name="code" className="mb-6 w-full" {...register('code')} isRequired />
        <Button
          color="primary"
          variant="ghost"
          className="mb-6"
          isDisabled={countDown < 60 || codeSent}
          onClick={() => {
            if (watch('account'))
              getLoginCode(getValues('account'), clToken).then((res) => {
                if (res.code === 200) {
                  setCodeSent(true)
                  notify(res.message)
                } else {
                  notify(res.message, 'warning')
                }
              })
          }}
        >
          {btnText}
        </Button>
      </div>
      <Turnstile siteKey={STIE_KEY} onSuccess={setClToken} />
      <Button
        color="primary"
        type="submit"
        className="w-full bg-gradient-to-r from-primary to-red-600"
        isLoading={loading}
        isDisabled={!checked}
        spinner={
          <svg className="h-5 w-5 animate-spin text-current" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
            <path className="opacity-75" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" fill="currentColor" />
          </svg>
        }
      >
        登录 / 注册
      </Button>
      {/* <div className="mt-6 flex items-center justify-center gap-8">
        <Image src="/auth/weixin.svg" alt="" width={30} height={30} radius="sm" className="border border-solid border-foreground/30" />
        <Button
          variant="light"
          isIconOnly
          onClick={() => {
            get('v1/user/douyin_login').then((res) => {
              if (res.code === 200) {
                window.location.href = res.data
              }
            })
          }}
        >
          <Image src="/auth/douyin.svg" alt="" width={30} height={30} radius="sm" className="border border-solid border-foreground/30" />
        </Button>
      </div> */}
      <div className="mt-6 text-center text-xs">
        <Checkbox
          size="sm"
          onChange={(e) => {
            setChecked(e.target.checked)
          }}
          defaultSelected={checked}
        />
        我已阅读并同意
        <Link href="/agreement" className="text-primary">
          《Ground AI用户协议与隐私》
        </Link>
      </div>
    </form>
  )
}

export default Login
