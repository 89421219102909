import { post } from '@/utils/request'

const login = async (params) => {
  const res = await post('user/login', { ...params })
  return res
}

const signup = async (params) => {
  const res = await post('v1/user/register', { ...params })
  return res
}

const getLoginCode = async (account, token) => {
  const res = await post(`v1/user/send_verification_code`, { account, cloudflare_token: token })
  return res
}

export { login, signup, getLoginCode }
