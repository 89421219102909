import { useEffect, useMemo, useRef } from 'react'

const Executor = (props) => {
  const { hook, onUpdate } = props

  const updateRef = useRef(onUpdate)
  updateRef.current = onUpdate
  const initialLoad = useRef(false)

  const data = hook()

  // 首次执行时立刻返回初始值
  useMemo(() => {
    updateRef.current(data)
    initialLoad.current = false
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // React 16.13 后 update 函数用 useEffect 包裹
  useEffect(() => {
    if (initialLoad.current) {
      updateRef.current(data)
    } else {
      initialLoad.current = true
    }
  }, [data])

  return null
}

export default Executor
