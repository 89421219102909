import React, { useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import { Navbar, Image, Modal, ModalContent, Button, NavbarBrand, NavbarContent, NavbarItem } from '@nextui-org/react'
import dynamic from 'next/dynamic'
import { getToken } from '@/utils/request'
import Auth from '@/components/Auth'
import { useModel } from '@/plugin-model/useModel'
import BackToTop from './BackTop'

const HeaderDropdown = dynamic(() => import('@/components/HeaderDropdown'), { ssr: false })

const App = ({ need_login, children }) => {
  const router = useRouter()
  const { view, viewSet } = useModel('view')

  const [isMenuOpen, setIsMenuOpen] = React.useState(false)

  const shouldRedirectLogin = useMemo(() => need_login && !getToken(), [need_login])

  useEffect(() => {
    if (shouldRedirectLogin) {
      router.replace(`/`)
    }
  }, [router, shouldRedirectLogin])

  const menuItems = [
    ['AI聊天', '/chat', 'chat'],
    ['AI绘画', '/creation/mj', 'creation'],
    ['画廊', '/gallery', 'gallery'],
  ]

  return (
    <div>
      <Navbar isBordered isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen} maxWidth="full" className="h-16">
        <NavbarContent justify="start">
          <NavbarBrand>
            <NavbarBrand className="flex items-center gap-3">
              <Image src="/logo.png" width={40} height={40} alt="" radius="full" onClick={() => router.push('/')} />
              <div className="hidden cursor-pointer font-bold text-inherit sm:block" onClick={() => router.push('/')} role="presentation">
                Ground AI
              </div>
            </NavbarBrand>
          </NavbarBrand>
        </NavbarContent>
        <NavbarContent className="hidden lg:flex" justify="start">
          <NavbarItem>
            <Button
              disableRipple
              className={`${router.asPath === '/' ? 'text-primary' : ''} "bg-transparent p-0 data-[hover=true]:bg-transparent`}
              radius="sm"
              variant="light"
              onClick={() => {
                router.push('/')
              }}
            >
              首页
            </Button>
          </NavbarItem>
          {menuItems.map((v) => (
            <NavbarItem key={v[1]}>
              <Button
                disableRipple
                className={`${router.asPath.includes(v[2]) ? 'text-primary' : ''} "bg-transparent p-0 data-[hover=true]:bg-transparent`}
                radius="sm"
                variant="light"
                onClick={() => {
                  router.push(v[1])
                }}
              >
                {v[0]}
              </Button>
            </NavbarItem>
          ))}
        </NavbarContent>
        <NavbarContent justify="end" className="relative">
          <HeaderDropdown needLogin={need_login} />
        </NavbarContent>
        <Modal isOpen={view.loginDialogOpen} onOpenChange={() => viewSet('loginDialogOpen', false)} size="sm" placement="center">
          <ModalContent>
            <Auth />
          </ModalContent>
        </Modal>
      </Navbar>
      <div className="fixed bottom-0 z-40 block w-full bg-black lg:hidden">
        <div className="flex h-[50px] w-full flex-row px-4 font-sans text-sm">
          <div className="flex w-full flex-1 flex-row items-center justify-around gap-0 bg-transparent bg-opacity-0 font-medium text-gray-100">
            <Button
              disableRipple
              radius="full"
              size="sm"
              variant={`${router.asPath === '/' ? 'solid' : 'light'}`}
              color={`${router.asPath === '/' ? 'primary' : 'default'}`}
              onClick={() => {
                router.push('/')
              }}
            >
              首页
            </Button>
            {menuItems.map((v) => (
              <Button
                key={v[1]}
                disableRipple
                radius="full"
                size="sm"
                variant={`${router.asPath.includes(v[2]) ? 'solid' : 'light'}`}
                color={`${router.asPath.includes(v[2]) ? 'primary' : 'default'}`}
                onClick={() => {
                  router.push(v[1])
                }}
              >
                {v[0]}
              </Button>
            ))}
          </div>
        </div>
      </div>
      <BackToTop />
      {children}
    </div>
  )
}

export default App
